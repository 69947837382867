import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import { Avatar } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import React from 'react';
import { connect } from 'react-redux';
import { isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';
import { NotificationsStore, ShopStore, UserStore } from '../../../stores';
import Icon from '../../Icon';
import Dropdown from '../../bs-dropdown';
import Placeholder from '../../../../config/placeholder.config';
import { language, messages } from '../../../utils/localeUtils';
import { notificationLink, updateReadReport } from '../../../pages/Alerts';
import { renderTimestamp } from '../../../utils/formatting';
import { config } from '../../../settings';
import { updateSettings as actionUpdateSettings } from '../../../actions';
import firebaseApp from '../../../../config/firebase.config';
import { signOutUser } from '../../../pages/Auth/actions';
import { login, redirectSignOut, register } from '../../../utils/redirect';

function NavbarRightButtons({ auth, settings, quickActions, updateSettings, signOutUser, setSearchAction }) {
  const notificationCount = NotificationsStore.useState((s) => s.notificationCount);
  const notifications = NotificationsStore.useState((s) => s.notifications);
  const cartCount = ShopStore.useState((s) => s.cart.items.length);

  const logOut = () => {
    signOutUser(() => {
      firebaseApp.auth().signOut().then(() => {
        redirectSignOut();
      });
    });
  };

  if (auth.authenticated && auth.user) {
    const { user } = auth;
    // console.log(JSON.stringify(user));
    if (isMobileOnly) {
      return (
        <>
          <Link to="/alerts" style={{ padding: '14px' }}>
            <Badge badgeContent={notificationCount} color="error">
              <Icon name="bell" />
            </Badge>
          </Link>
          <Link to="/cart" style={{ padding: '14px' }}>
            <Badge badgeContent={cartCount} color="success">
              <Icon name="shopping-cart" />
            </Badge>
          </Link>
        </>
      );
    }
    return (
      <>
        {!isTablet && (
          <Dropdown tag="li" direction="up" openOnHover={!isTablet} showTriangle style={{ width: 85 }}>
            <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar mnr-6">
              <img src={user.image_url || Placeholder.user} alt="" style={{ minWidth: 28 }} />
              <span>{user.first_name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu tag="ul" className="nav dropdown-menu">
              {auth && auth.user && (auth.user.account_type === 'business' || auth.user.account_type === 'admin') && (
                <li>
                  <a href={`${config.businessUrl}?token=${UserStore.getRawState().token}`} target="_blank" rel="noopener" className="nav-link">
                    <Icon name="business" vendor="material" />
                    <span>{messages[language]?.business_manager || 'Business Manager'}</span>
                    <span className="rui-nav-circle" />
                  </a>
                </li>
              )}
              <li>
                <Link to="/profile" className="nav-link">
                  <Icon name="user" />
                  <span>Manage Profile</span>
                  <span className="rui-nav-circle" />
                </Link>
              </li>
              <li>
                <Link to="/profile/orders" className="nav-link">
                  <Icon name="package" />
                  <span>My Orders</span>
                  <span className="rui-nav-circle" />
                </Link>
              </li>
              <li>
                <Link to="/settings" className="nav-link">
                  <Icon name="settings" />
                  <span>Settings</span>
                  <span className="rui-nav-circle" />
                </Link>
              </li>
              <li>
                <div className="custom-control custom-switch dropdown-item-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="toggleNightMode"
                    checked={settings.night_mode}
                    onChange={() => {
                      console.log('Toggling night mode...');
                      console.log(`Night mode is ${!settings.night_mode ? 'Off' : 'On'}`);
                      updateSettings({ night_mode: !settings.night_mode });
                    }}
                  />
                  { /* eslint-disable-next-line */}
                  <label className="dropdown-item custom-control-label" htmlFor="toggleNightMode">
                    <Icon name="moon" />
                    <span>Dark Mode</span>
                    <span className="rui-dropdown-circle" />
                  </label>
                </div>
              </li>
              <hr />
              <li>
                <Link
                  to="#"
                  className="nav-link"
                  onClick={logOut}
                >
                  <Icon name="log-out" />
                  <span>Log Out</span>
                  <span className="rui-nav-circle" />
                </Link>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Dropdown tag="li" direction="up" openOnHover showTriangle style={{ padding: '0 4px 0 10px' }}>
          <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar mnr-6">
            <Badge badgeContent={notificationCount} color="error">
              <Icon name="bell" />
            </Badge>
          </Dropdown.Toggle>
          <Dropdown.Menu tag="ul" className="nav dropdown-menu">
            <div style={{ width: 300, maxHeight: '60vh', overflowY: 'scroll' }}>
              <h3>{messages[language]?.notifications || 'Notifications'}</h3>
              {notifications.length === 0 && (
                <span style={{ color: settings.night_mode ? '#efefef' : 'inherit' }}>{'You\'re all caught up!'}</span>
              )}
              {notifications.map((notification) => (
                <li>
                  <Link to={() => notificationLink(notification)}>
                    <ListItem button key={`notification-${notification.id}`} onClick={() => updateReadReport(notification)}>
                      <Avatar src={notification.image_url} style={{ height: 64, width: 64, marginRight: 22 }} />
                      <ListItemText
                        primary={<span style={{ fontSize: '1.1rem', fontWeight: notification.read ? 400 : 700 }}>{notification.content}</span>}
                        secondary={<span style={{ color: notification.read ? 'inherit' : '#d6c290', fontWeight: notification.read ? 400 : 700 }}>{renderTimestamp(notification.timestamp)}</span>}
                      />
                      {!notification.read && (
                        <ListItemSecondaryAction style={{ right: 30 }}>
                          <Badge badgeContent=" " color="primary" variant="dot" />
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  </Link>
                </li>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
        {/* TODO Dropdown cart too with link to /cart on click? */}
        <Link to="/cart" style={{ padding: '0 14px' }}>
          <Badge badgeContent={cartCount} color="primary">
            <Icon name="shopping-cart" />
          </Badge>
        </Link>
        <Dropdown
          tag="li"
          direction="up"
          openOnHover
          showTriangle
          style={{
            padding: '0px 7px 0px 7px',
            height: 30,
            width: 30,
            backgroundColor: 'rgb(213, 193, 150)',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px -1px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 0px 0px',
            borderRadius: '50%',
          }}
        >
          {/* FIXME Any way to force the trigger to be a tap on tablets? It doesn't work so smoothly since the trigger is hover */}
          <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar mnr-6">
            <Icon name="plus" style={{ color: 'rgba(0,0,0,0.2)' }} />
          </Dropdown.Toggle>
          <Dropdown.Menu tag="ul" className="nav dropdown-menu" style={{ width: 300 }}>
            <h3>Quick Actions</h3>
            {quickActions.map((item) => (
              <li
                onClick={() => item.action(() => {
                  setSearchAction({
                    route: item.route,
                    params: item.params,
                    results: item.results,
                  });
                })}
              >
                <Link to={item.path}>
                  <ListItem button key={`notification-${item.name}`}>
                    <Icon name={item.icon} style={{ marginRight: 16 }} />
                    <ListItemText primary={<span style={{ fontSize: '1.1rem' }}>{item.name}</span>} />
                  </ListItem>
                </Link>
              </li>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
  return (
    <div style={{ marginTop: isMobile ? 0 : 8 }}>
      <a
        href="#"
        style={{
          display: isMobileOnly ? 'none' : 'initial',
          marginRight: 20,
        }}
        onClick={() => {
          login(`${window.location.protocol}//${window.location.host}/profile`);
        }}
      >
        {'Log In'}
      </a>
      <a
        href="#"
        className="btn-outline-grey"
        style={{
          padding: '5px 10px',
          borderRadius: 5,
          marginTop: -5,
        }}
        onClick={() => {
          register(`${window.location.protocol}//${window.location.host}/profile`);
        }}
      >
        {'Get Started'}
      </a>
      <Link to="/cart" style={{ padding: '0 14px' }}>
        <Badge badgeContent={cartCount} color="primary">
          <Icon name="shopping-cart" />
        </Badge>
      </Link>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
    settings: state.get('settings'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  signOutUser: (callback) => dispatch(signOutUser(callback)),
  updateSettings: (settings) => dispatch(actionUpdateSettings(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarRightButtons);
