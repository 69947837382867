import React, { PureComponent } from 'react';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { isIOS, isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';
import Chip from '@material-ui/core/Chip';
import {
  resetSearchAction as actionResetSearchAction,
  setSearchAction as actionSetSearchAction,
  updateAuth as actionUpdateAuth,
  updateSettings as actionUpdateSettings,
} from '../../actions';
import Dropdown from '../bs-dropdown';
import Icon from '../Icon';
import { selectWpPostListEntities } from '../../pages/WpPostList/selectors';
import { initNavbar } from '../../../common-assets/js/rootui-parts/initNavbar';
import { signOutUser } from '../../pages/Auth/actions';
import PlusToXIcon from '../PlusToXIcon';

import './style.scss';
import MobileSearchBar from '../MobileSearchBar';
import SearchResults from '../search-results';
import { language, messages } from '../../utils/localeUtils';
import MainSearchBar from '../MainSearchBar';
import { NotificationsStore, UIStore } from '../../stores';
import QuickActionSearchModal from '../QuickActionSearchModal';
import NavbarRightButtons from './components/right-buttons';
import MobileMenuIcon from '../MobileMenuIcon';
import { search } from '../../utils/actions/search.actions';

const $ = window.jQuery;

window.RootUI.initNavbar = initNavbar;

let lastQuery = '';

// FIXME Convert this to a functional component - we can make use of hooks
class PageNavbar extends PureComponent {
  constructor(props) {
    super(props);

    window.RootUI.initNavbar();

    this.state = {
      mobileMenuShow: false,
      showSearchResults: false,
      showQuickActionSearch: false,
      shouldShowQuickActions: false,
      searchResults: {},
      quickActions: [],
      linkToHome: false,
    };

    this.renderSubmenus = this.renderSubmenus.bind(this);
    this.renderRightMenuItems = this.renderRightMenuItems.bind(this);

    this.mobileSearchBar = React.createRef();
    this.quickActionsButton = React.createRef();
    this.quickActionsDropdown = React.createRef();

    this.outsideClickListener = this.outsideClickListener.bind(this);
  }

  componentDidMount() {
    if (isMobileOnly) {
      document.addEventListener('click', this.outsideClickListener);
    }
    $(document).on('keydown.rui-navbar', (e) => {
      const {
        mobileMenuShow,
      } = this.state;

      if (mobileMenuShow && e.keyCode === 27) {
        this.setState({
          mobileMenuShow: !mobileMenuShow,
        });
      }
    });

    $(document).ready(() => {
      window.addEventListener('showSearch', (e) => {
        console.log(`Called event listener: ${e.type}`);
        console.log(e);
        console.log(e.detail);
        if (e.detail && e.detail.action) {
          const { action } = e.detail;
          this.props.setSearchAction({
            route: action.route,
            params: action.params,
            results: action.results,
            replace: action.replace,
          });
        }
        this.setState({
          showSearchResults: true,
          showQuickActionSearch: true,
        }, () => {
          jQuery('body').addClass('no-scroll');
          // disableBodyScroll(document.querySelector('.search-results-container'));
          $('.search-results-container').addClass('overlay');
          $('#main-mobile-search-bar').addClass('overlay');
          $('#main-mobile-search-bar').css('display', 'flex');
        });
      });

      window.addEventListener('hideSearch', (e) => {
        if (this.state.showSearchResults) {
          // FIXME This causes infinite loop
          this.props.resetSearchAction();
          this.setState({
            showSearchResults: false,
            showQuickActionSearch: false,
          }, () => {
            jQuery('body').removeClass('no-scroll');
            // enableBodyScroll(document.querySelector('.search-results-container'));
            $('.search-results-container').removeClass('overlay');
            $('#main-mobile-search-bar').removeClass('overlay');
            if (e.detail && e.detail.removeSearchBar) {
              $('#main-mobile-search-bar').css('display', 'none');
            }
          });
        }
      });
    });
  }

  componentWillUnmount() {
    $(document).off('keydown.rui-navbar');
    if (isMobileOnly) {
      document.removeEventListener('click', this.outsideClickListener);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const nav = nextProps.settings.navigation;
    Object.keys(nav).forEach((url) => {
      const data = nav[url];
      if (data.chips) {
        this.setState({
          quickActions: data.chips,
        });
      }
    });

    if (window.location.pathname === '/') {
      this.setState({
        linkToHome: false,
        shouldShowQuickActions: false,
      });
    } else {
      this.setState({
        linkToHome: true,
        shouldShowQuickActions: true,
      });
    }
  }

  toggleSidebar() {
    UIStore.update((s) => {
      s.showMenu = !s.showMenu;
    });
  }

  outsideClickListener = (event) => {
    console.log('Clicked outside of element...');
    if (this.quickActionsDropdown && this.quickActionsDropdown.current && !this.quickActionsDropdown.current.contains(event.target)
      && this.quickActionsButton && this.quickActionsButton.current && !this.quickActionsButton.current.contains(event.target)) {
      console.log('Closing quick actions...');
      jQuery('#more-to-x-checkbox').prop('checked', false);
      this.setState({
        mobileMenuShow: false,
      });
    }
  };

  hideSearch = () => {
    console.log('Hiding search...');
    this.setState({
      showSearchResults: false,
    }, () => {
      $(document).find('.search-results-container').removeClass('overlay');
      $(document).find('#main-mobile-search-bar').removeClass('overlay');
    });
  };

  notificationCount = () => NotificationsStore.getRawState().notificationCount;

  // FIXME https://bitbucket.org/boxpressd/boxpressd-react/src/master/app/containers/App/Search.js
  search = (query) => {
    if (query.length > 2 && query !== lastQuery) {
      lastQuery = query;
      // FIXME If a result type is set, this shouldn't search on all types
      console.log('Calling search requests from props...');
      search(query, {
        cigars: true,
        venues: true,
        products: true,
        users: true,
        groups: true,
        articles: true,
      });
    }
  };

  renderQuickActions = () => {
    const { quickActions } = this.state;
    return (
      <div
        ref={this.quickActionsDropdown}
        style={{
          height: 44,
          overflowX: 'scroll',
          overflowScrolling: 'touch',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        { quickActions.map((item) => (
          <div style={{ margin: 5, display: 'inline-block' }}>
            <a href={item.path} target="_blank" rel="noreferrer">
              <Chip
                icon={<Icon name={item.icon} />}
                color="default"
                label={item.name}
                onClick={() => item.action(() => {
                  jQuery('#more-to-x-checkbox').prop('checked', false);
                  jQuery('#mobile-search-input').focus();

                  console.log('Clicked Chip:');
                  console.log(item);
                  this.props.setSearchAction({
                    route: item.route,
                    params: item.params,
                    results: item.results,
                  });
                  this.setState({
                    mobileMenuShow: false,
                    showSearchResults: true,
                  });
                })}
              />
            </a>
          </div>
        ))}
      </div>
    );
  };

  renderSubmenus(nav, level = 1) {
    return Object.keys(nav).map((url) => {
      const data = nav[url];

      const isActive = window.location.hash === `#${url}`;

      const LinkContent = data.name ? (
        <>
          { data.icon ? (
            <>
              <Icon name={data.icon} />
              <span>{ data.name }</span>
              <span className={data.sub ? 'rui-dropdown-circle' : 'rui-nav-circle'} />
            </>
          ) : (
            data.name
          ) }
        </>
      ) : '';

      return (
        <React.Fragment key={`${url}-${data.name}`}>
          { data.sub ? (
            <Dropdown tag="li" className={classnames(isActive ? 'active' : '')} direction={level === 1 ? 'up' : 'right'} openOnHover={!isMobile} showTriangle>
              <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
                { LinkContent }
              </Dropdown.Toggle>
              <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                { this.renderSubmenus(data.sub, level + 1) }
              </Dropdown.Menu>
            </Dropdown>
          ) : data.chips ? (
            this.renderQuickActions()
          ) : (
            <li className={classnames('nav-item', isActive ? 'active' : '')}>
              <Link
                to={data.sub ? '#' : url}
                className="nav-link"
              >
                { LinkContent }
              </Link>
            </li>
          ) }
        </React.Fragment>
      );
    });
  }

  renderRightMenuItems() {
    return (
      <NavbarRightButtons
        setSearchAction={(params) => {
          this.props.setSearchAction(params);
          this.setState({
            showQuickActionSearch: true,
          });
        }}
        quickActions={this.state.quickActions}
      />
    );
  }

  render() {
    const { settings, posts } = this.props;
    const { mobileMenuShow, searchResults, showSearchResults, linkToHome } = this.state;

    const { width } = window.screen;

    if (posts) {
      console.log('Got articles...');
      console.log(posts.toArray());
      // TODO Build the rest from Saga/Reselect
      searchResults.articles = posts.toArray();
    }

    // FIXME Should probably split this between mobile and desktop (and tablet - iPad Pro should use desktop version but others are more like mobile?)
    return (
      <>
        { /* Nav Menu */ }
        <nav
          className={
            classnames(
              'rui-navbar rui-navbar-top',
              settings.nav_dark ? 'rui-navbar-dark' : '',
              settings.nav_sticky && !isIOS ? 'rui-navbar-sticky' : '',
              settings.nav_fixed ? 'rui-navbar-fixed' : '',
              settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
            )
          }
        >
          <div className="rui-navbar-brand">
            { settings.nav_logo_path ? (
              <Link to={settings.nav_logo_url} className="rui-navbar-logo">
                <img src={settings.nav_logo_path} alt="Boxpressd Cigar App" width="125" style={{ maxHeight: 22 }} />
              </Link>
            ) : '' }

            {/*<button className="yay-toggle rui-yaybar-toggle" type="button">*/}
            {/*  <span />*/}
            {/*</button>*/}
          </div>
          <div className={`container${settings.nav_container_fluid ? '-fluid' : ''}`}>
            <div className="rui-navbar-content">
              <div className="nav">
                { !isMobile && (
                  <MainSearchBar
                    ref={(el) => this.mobileSearchBar = el}
                    style={{ width: 500, display: 'inline-block', position: 'absolute', top: 5, zIndex: 1001, padding: 0, height: 44 }}
                    placeholder={`${messages[language]?.search || 'Search Boxpressd'}...`}
                    onQueryChange={this.search}
                    onEnter={(query) => {
                      this.props.history.push({
                        pathname: '/search',
                        search: `?q=${query}`,
                      });
                    }}
                    showClearButton
                    onFocus={() => {
                      this.setState({
                        showSearchResults: true,
                      });
                    }}
                    onClear={() => {
                      jQuery('#clear-mobile-search-btn').hide();
                      this.props.resetSearchAction();
                      this.setState({
                        showSearchResults: false,
                      });
                    }}
                  />
                )}
                {/* { !isMobile && this.renderQuickActions() } */}
              </div>
              <ul className="nav rui-navbar-right" style={{ marginTop: isMobile ? 6 : 2, marginBottom: isMobile ? 0 : 2 }}>
                { this.renderRightMenuItems() }
              </ul>
            </div>
          </div>
        </nav>

        { /* Mobile Menu */ }
        <nav
          className={
            classnames(
              'rui-navbar rui-navbar-mobile',
              settings.nav_dark ? 'rui-navbar-dark' : '',
              settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
              mobileMenuShow ? 'rui-navbar-show' : '',
            )
          }
          style={{
            boxShadow: 'none',
            position: 'fixed',
          }}
        >
          <div className="rui-navbar-head">
            { (isIOS && window.location.pathname !== '/') ? (
              <button onClick={() => window.history.back()} type="button" style={{ border: 'none', background: 'transparent', marginRight: 10 }}>
                <Icon name="chevron-left" size={24} style={{ color: '#586069' }} />
              </button>
            ) : '' }
            { !mobileMenuShow ? (
              <MobileMenuIcon toggleSideMenu={this.toggleSidebar} />
            ) : '' }
            { linkToHome && settings.nav_logo_path ? (
              <Link to={settings.nav_logo_url} className="rui-navbar-logo mr-auto" style={{ marginTop: isTablet ? 3 : 'inherit', marginLeft: isTablet ? 10 : 'inherit' }}>
                {width > 320 && <img src={(settings.night_mode || settings.nav_dark) ? settings.nav_logo_white_path : settings.nav_logo_path} alt="Boxpressd Cigar App" width={settings.nav_logo_width} />}
              </Link>
            ) : '' }
            { !linkToHome && settings.nav_logo_path ? (
              <span className="rui-navbar-logo mr-auto" style={{ marginTop: isTablet ? 3 : 'inherit', marginLeft: isTablet ? 10 : 'inherit' }} onClick={() => window.dispatchEvent(new CustomEvent('scrollFeedToTop'))}>
                {width > 320 && <img src={(settings.night_mode || settings.nav_dark) ? settings.nav_logo_white_path : settings.nav_logo_path} alt="Boxpressd Cigar App" width={settings.nav_logo_width} />}
              </span>
            ) : '' }
            { this.renderRightMenuItems(true) }
            {this.state.shouldShowQuickActions && (this.props.auth && this.props.auth.user) && isMobileOnly && (
              <button
                ref={this.quickActionsButton}
                className="navbar-toggler rui-navbar-toggle ml-5"
                style={{
                  height: 30,
                  width: 30,
                  backgroundColor: 'rgb(213, 193, 150)',
                  borderRadius: '50%',
                  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px -1px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 0px 0px',
                  marginRight: 0,
                }}
              >
                <PlusToXIcon
                  isOpen={this.state.mobileMenuShow}
                  onClick={() => {
                    console.log('Clicked plus icon');
                    this.setState({
                      mobileMenuShow: !mobileMenuShow,
                    });
                  }}
                />
              </button>
            )}
          </div>
          {isMobileOnly && (
            <Collapse isOpen={mobileMenuShow} className="navbar-collapse rui-navbar-collapse">
              <div className="rui-navbar-content">
                <ul className="nav">
                  { this.renderSubmenus(settings.navigation, true) }
                </ul>
              </div>
            </Collapse>
          )}
        </nav>
        { isMobile && (
          <MobileSearchBar
            ref={(el) => this.mobileSearchBar = el}
            id="main-mobile-search-bar"
            placeholder={`${messages[language]?.search || 'Search'}...`}
            onQueryChange={this.search}
            showClearButton
            onFocus={() => {
              jQuery('#quick-action-chips').hide();
              jQuery('body').addClass('no-scroll');
              this.setState({
                showSearchResults: true,
              });
            }}
            onClear={() => {
              jQuery('#quick-action-chips').show();
              this.props.resetSearchAction();
              jQuery('#quick-action-chips').show(); // FIXME Why is it here twice?
              console.log('window.location.pathname');
              console.log(window.location.pathname);
              if (window.location.pathname !== '/') {
                jQuery('body').removeClass('no-scroll');
              }
              if (window.location.pathname.indexOf('/profile/humidors') !== -1) {
                if (document.getElementById('main-mobile-search-bar')) {
                  document.getElementById('main-mobile-search-bar').style.display = 'none';
                }
                $('#main-mobile-search-bar').removeClass('overlay');
              }
              this.setState({
                showSearchResults: false,
              });
            }}
          />
        )}
        {/* TODO Probably want to show a placeholder image like herf and other pages (and FB) */}
        { isMobile && showSearchResults && (
          <SearchResults
            // loading={this.state.loadingSearchResults}
            // results={searchResults}
            onOpen={() => {
              // If the search bar is hidden, make sure we show it
              $('#main-mobile-search-bar').css('display', 'flex');
            }}
            onClose={this.hideSearch}
            onSelect={() => {
              if (this.mobileSearchBar) {
                this.mobileSearchBar.clearQuery();
              }
              jQuery('#quick-action-chips').show();
              this.hideSearch();
            }}
          />
        )}
        <div
          className="rui-navbar-bg"
          onClick={() => {
            this.setState({
              mobileMenuShow: !mobileMenuShow,
            });
          }}
          onKeyUp={() => {}}
          role="button"
          tabIndex={0}
        />
        {!isMobileOnly && (
          <QuickActionSearchModal
            open={this.state.showQuickActionSearch}
            onQueryChange={this.search}
            closeModal={() => {
              this.setState({ showQuickActionSearch: false });
            }}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
    settings: state.get('settings'),
    posts: selectWpPostListEntities('all')(state),
  };
}
const mapDispatchToProps = (dispatch, props) => ({
  signOutUser: (callback) => dispatch(signOutUser(callback)),
  updateAuth: actionUpdateAuth,
  updateSettings: (settings) => dispatch(actionUpdateSettings(settings)),
  setSearchAction: (data) => dispatch(actionSetSearchAction(data)),
  resetSearchAction: () => dispatch(actionResetSearchAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageNavbar));
